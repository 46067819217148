import Header from "../components/Header/Header";
import { BulletList } from "react-content-loader";

export default function Loader() {
  return (
    <>
      <Header />
      <main>
        <BulletList aria-label="loading" />
      </main>
    </>
  );
}
