import Breadcrumbs from "../components/Breadcrumbs/Breadcrumbs";
import Header from "../components/Header/Header";
import Message from "../components/Message/Message";

interface FrontendErrorProps {
  error: Error;
}
export default function FrontendError({ error }: FrontendErrorProps) {
  return (
    <>
      <Header />
      <main>
        <Breadcrumbs
          breadcrumbs={[{ name: "Home", href: "/" }, { name: "Error" }]}
        />
        <Message variant="failure">
          Oops an error occurred, please contact a system administrator
          <details>
            <summary>error details</summary>
            <pre>{error?.message || String(error)}</pre>
          </details>
        </Message>
      </main>
    </>
  );
}
