import { Link } from "react-router-dom";
import classnames from "clsx";
import "./Breadcrumbs.scss";

interface Breadcrumb {
  name: string;
  href?: string;
}

interface BreadcrumbProps {
  breadcrumbs: Breadcrumb[];
}

export default function Breadcrumbs({ breadcrumbs }: BreadcrumbProps) {
  return (
    <nav className="breadcrumbs">
      <ul className="breadcrumbs__list">
        {breadcrumbs.map(({ name, href }, index, { length }) => {
          if (href) {
            return (
              <li
                key={href}
                className={classnames("breadcrumbs__item", {
                  "breadcrumbs__item--active": index === length - 1,
                })}
              >
                <Link className="breadcrumbs__link" to={href}>
                  {name}
                </Link>
              </li>
            );
          }

          return (
            <li
              key={name}
              className={classnames("breadcrumbs__item", {
                "breadcrumbs__item--active": index === length - 1,
              })}
            >
              <span className="breadcrumbs__link">{name}</span>
            </li>
          );
        })}
      </ul>
    </nav>
  );
}
