import { lazy, Suspense, useEffect, useState } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import Loader from "./pages/Loader";
import ErrorPage from "./pages/ErrorPage";
import {
  getAccessToken,
  getRedirectLocation,
  REDIRECT_TO_SIGNOUT,
  startAuthenticationStatusChecks,
} from "./utils/authStateManagement";

const Home = lazy(() => import("./pages/Home"));
const ViewScale = lazy(() => import("./pages/ViewScale"));
const ViewAssessmentAndAssociation = lazy(
  () => import("./pages/ViewAssessmentAssociation")
);
const MapAssessment = lazy(() => import("./pages/MapAssessment"));
const NewScale = lazy(() => import("./pages/NewScale"));
const NewLabelSet = lazy(() => import("./pages/NewLabelSet"));
const ViewLabelSet = lazy(() => import("./pages/ViewLabelSet"));
const Signout = lazy(() => import("./pages/auth/Signout"));
const NotFound = lazy(() => import("./pages/NotFound"));
const Welcome = lazy(() => import("./pages/Welcome"));
const RedirectPage = lazy(() => import("./pages/auth/Redirect"));

interface ProtectedRouteProps {
  path?: string;
  exact?: boolean;
  component: any;
}

const ProtectedRoute = (props: ProtectedRouteProps) => {
  const { component: Component, ...rest } = props;
  const redirectNoToken = getAccessToken() === null;
  const redirectPathSet = getRedirectLocation() !== null;
  // Currently there's only one redirect location.  Default is /welcome
  const redirectPath =
    getRedirectLocation() === REDIRECT_TO_SIGNOUT
      ? "/auth/signout"
      : "/welcome";
  return (
    <Route
      {...rest}
      render={(props) =>
        redirectNoToken || redirectPathSet ? (
          <Redirect to={redirectPath} />
        ) : (
          <Component {...props} />
        )
      }
    />
  );
};

export default function Router() {
  // Prior to calling `setUpdateRequest(true)` in the status check interval below, it's expected that the auth state is updated such that `getAccessToken()` in the `ProtectedRoute` will be consistent with the application state.
  // Note we don't use the value of updateRequest, we just care that the state changed.
  const [, setUpdateRequest] = useState(false);

  useEffect(() => {
    const interval = startAuthenticationStatusChecks(setUpdateRequest);
    return () => clearInterval(interval);
  }, []); // Use an empty array since we only want 1 interval at a time

  return (
    <Suspense fallback={Loader}>
      <BrowserRouter>
        <ErrorBoundary FallbackComponent={ErrorPage}>
          <Switch>
            <ProtectedRoute path="/" exact component={Home} />
            <ProtectedRoute path="/scale/new" component={NewScale} />
            <ProtectedRoute path="/scale/:id" component={ViewScale} />
            <ProtectedRoute
              path="/assessment/:id/map"
              component={MapAssessment}
            />
            <ProtectedRoute
              path="/assessment/:id"
              component={ViewAssessmentAndAssociation}
            />
            <ProtectedRoute path="/label-set/new" component={NewLabelSet} />
            <ProtectedRoute path="/label-set/:id" component={ViewLabelSet} />
            <Route path="/welcome" component={Welcome} exact />
            <Route path="/auth/redirect" component={RedirectPage} />
            <Route path="/auth/signout" component={Signout} exact />
            <Route component={NotFound} />
          </Switch>
        </ErrorBoundary>
      </BrowserRouter>
    </Suspense>
  );
}
