import Logo from "../Logo/Logo";
import "./Header.scss";
import { getAccessToken } from "../../utils/authStateManagement";
import { Link } from "react-router-dom";

export default function Header() {
  return (
    <header className="header">
      <section className="header__title">
        <Logo />
      </section>
      <section className="header__actions">
        <section
          className="header__action-right-hand-links"
          title="Have questions? View the supporting Google Doc page for help"
        >
          <a
            id="header-link-documentation"
            className="header__action-right-hand-link"
            href="https://docs.google.com/document/d/1P3-_zu-x28bQoRhTNMBnAX29rjtiHIu_kUkDjw4mAq0/preview"
            target="_blank"
            rel="noopener noreferrer"
          >
            DOCUMENTATION
          </a>
        </section>
        {getAccessToken() !== null && (
          <section
            className="header__action-right-hand-links"
            title="Sign out of Assessment Scales"
          >
            <Link
              id="header-link-signout"
              className="header__action-right-hand-link"
              to={"/auth/signout"}
            >
              SIGN OUT
            </Link>
          </section>
        )}
      </section>
    </header>
  );
}
