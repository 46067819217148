const Configs = {
  Auth: {
    region: process.env.REACT_APP_AWS_REGION,
    userPoolId: process.env.REACT_APP_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID,
    oauth: {
      domain: process.env.REACT_APP_USER_POOL_DOMAIN,
      scope: ["profile", "email", "openid", "aws.cognito.signin.user.admin"],
      redirectSignIn: process.env.REACT_APP_USER_POOL_SIGN_IN_REDIRECT,
      redirectSignOut: process.env.REACT_APP_USER_POOL_SIGN_OUT,
      responseType: "code",
    },
  },
  API: {
    endpoints: [
      {
        name: process.env.REACT_APP_AWS_API_GATEWAY_ID,
        endpoint: process.env.REACT_APP_AWS_API_GATEWAY_BASE_URL,
        region: process.env.REACT_APP_AWS_REGION,
      },
    ],
  },
};

export default Configs;
