import debugConstructor from "debug";
import { SetStateAction } from "react";
import awsConfig from "../aws-exports";
import {
  RefreshedTokens,
  RefreshedTokensSchema,
  Tokens,
  TokensSchema,
} from "../utils/schemas";

const debug = debugConstructor("scales:auth");

export const AUTH_ERROR_MISSING_CODE = "auth_error_missing_code";
export const AUTH_ERROR_NO_REFRESH_TOKEN = "auth_error_no_refresh_token";
export const AUTH_ERROR_NO_ACCESS_TOKEN = "auth_error_no_access_token";
export const AUTH_ERROR_UNABLE_TO_REFRESH_TOKEN =
  "auth_error_unable_to_refresh_token";
export const AUTH_ERROR_UNABLE_TO_EXCHANGE_CODE_FOR_TOKENS =
  "auth_error_unable_to_exchange_tokens";
export const AUTH_ERROR_UNABLE_TO_AUTH_PROPERLY =
  "auth_error_unable_to_auth_properly";
export const AUTH_ERROR_UNABLE_TO_DETERMINE_AUTH_TIMEOUT_AUTO_SIGNED_OUT =
  "auth_error_unable_to_determine_auth_timeout_auto_signed_out";
export const AUTH_ERROR_UNEXPECTED_TOKEN_RESPONSE =
  "auth_error_unexpected_token_response";
const AUTH_ERROR_NO_REFRESH_TOKEN_TO_REVOKE =
  "auth-error-no-reresh-token-to-revoke";
const AUTH_ERROR_FAILED_TO_REVOKE_TOKENS = "auth-error-failed-to-revoke-tokens";

export const AUTH_MESSAGE_SIGNED_OUT = "auth_message_signed_out";
export const AUTH_MESSAGE_SIGNING_IN = "auth_message_signing_in";
export const AUTH_MESSAGE_AUTO_SIGNED_OUT = "auth_message_auth_signed_out";

export const REDIRECT_TO_SIGNOUT = "redirect-to-signout";

/**
 * Safely display auth_message
 *
 * @param key from the AUTH_MESSAGE_* consts
 * @returns a descriptive text of the key
 */
export function convertMessageKeyToDisplayValue(
  key: string | null
): string | null {
  let value = null;
  switch (key) {
    case null:
      // no op
      break;
    case AUTH_MESSAGE_SIGNED_OUT:
      value =
        "Signed out of Assessment Scales.  Please login to access the site.";
      break;
    case AUTH_MESSAGE_AUTO_SIGNED_OUT:
      value =
        "You have been signed out of Assessment Scales.  Please login to access the site.";
      break;
    case AUTH_MESSAGE_SIGNING_IN:
      value = "Signing into Assessment Scales.  Please wait...";
      break;
    default:
      console.log("Unknown auth message: " + key);
      value = "";
  }
  return value;
}

/**
 * Safely display auth_error
 *
 * @param key from the AUTH_ERROR_* consts
 * @returns a descriptive text of the key
 */
export function convertErrorKeyToDisplayValue(
  key: string | null
): string | null {
  let value = null;
  switch (key) {
    case null:
      // no op
      break;
    case AUTH_ERROR_MISSING_CODE:
      value = " - missing code";
      break;
    case AUTH_ERROR_NO_REFRESH_TOKEN:
      value = "- no refresh token available";
      break;
    case AUTH_ERROR_NO_ACCESS_TOKEN:
      value = " - no access token available";
      break;
    case AUTH_ERROR_UNABLE_TO_REFRESH_TOKEN:
      value = " - unable to refresh access token";
      break;
    case AUTH_ERROR_UNABLE_TO_EXCHANGE_CODE_FOR_TOKENS:
      value = " - unable to exchange code for access token";
      break;
    case AUTH_ERROR_UNABLE_TO_AUTH_PROPERLY:
      value = " - unable to authenticate properly";
      break;
    case AUTH_ERROR_UNEXPECTED_TOKEN_RESPONSE:
      value = " - unexpected response from IdP";
      break;
    case AUTH_ERROR_NO_REFRESH_TOKEN_TO_REVOKE:
      value =
        " - unable to signout from Cognito due to no refresh token. Logging user out locally";
      break;
    case AUTH_ERROR_FAILED_TO_REVOKE_TOKENS:
      value = " - unable to signout from Cognito. Logging user out locally";
      break;
    default:
      console.log("Unknown auth error: " + value);
      value = "";
  }

  return value === null
    ? value
    : "Error authenticating" + value + ". Please contact your administrator.";
}

export function setAccessToken(token: string) {
  localStorage.setItem("auth_access_token", token);
}

export function getAccessToken(): string | null {
  return localStorage.getItem("auth_access_token");
}

export function setRefreshToken(token: string) {
  localStorage.setItem("auth_refresh_token", token);
}

export function getRefreshToken(): string | null {
  return localStorage.getItem("auth_refresh_token");
}

/**
 * Removes the auth token values from localStorage
 *
 * @param clear_timeouts Also clears the timeout values from localStorage
 */
export function clearAuthTokens(clear_timeouts = false) {
  localStorage.removeItem("auth_access_token");
  localStorage.removeItem("auth_refresh_token");
  if (clear_timeouts) {
    clearAuthEndTimes();
  }
}

function clearAuthEndTimes() {
  localStorage.removeItem("auth_time_to_refresh_access_token");
  localStorage.removeItem("auth_time_to_logout");
}

export function removeAuthError() {
  localStorage.removeItem("auth_status_error");
}

export function removeAuthMessage() {
  localStorage.removeItem("auth_status_message");
}

export function getAuthError(): string | null {
  return localStorage.getItem("auth_status_error");
}

export function getAuthMessage(): string | null {
  return localStorage.getItem("auth_status_message");
}

export function setAuthError(error: string) {
  localStorage.setItem("auth_status_error", error);
  removeAuthMessage();
}

export function setAuthMessage(message: string) {
  localStorage.setItem("auth_status_message", message);
  removeAuthError();
}

/**
 * Exchanges the Cognito `code` for a set of tokens
 *
 * If successful, the tokens are cached
 * If the exchange failed, an auth error is set
 *
 * The caller is expected to redirect the user to the welcome page
 *
 * @param code from Cognito
 */
export async function exchangeCodeForTokens(code: string) {
  const domain = awsConfig.Auth.oauth.domain;
  const url = `https://${domain}/oauth2/token`;
  const details = {
    code: code,
    grant_type: "authorization_code",
    client_id: awsConfig.Auth.userPoolWebClientId!, // tell typescript trust this is a string
    redirect_uri: awsConfig.Auth.oauth.redirectSignIn!, // tell typescript trust this is a string
  };
  debug("Logging in via Cognito");
  const response = await fetch(url, {
    method: "POST",
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
    body: Object.entries(details)
      .map(
        ([key, value]) =>
          encodeURIComponent(key) + "=" + encodeURIComponent(value)
      )
      .join("&"),
  });

  if (response.ok) {
    debug("Successful response back from Cognito");
    // Cache tokens
    try {
      const tokenJson: Tokens = TokensSchema.parse(await response.json());
      setAccessToken(tokenJson.access_token);
      setRefreshToken(tokenJson.refresh_token);
      removeAuthMessage();
      removeAuthError();

      resetAuthenticationTimeoutTargets(true);
    } catch (e) {
      console.error(
        "Unable to parse / cache token json.  Going back to login page."
      );
      console.error(e);
      setAuthError(AUTH_ERROR_UNEXPECTED_TOKEN_RESPONSE);
      clearAuthTokens(true);
    }
  } else {
    debug("Failed to log in via Cognito");
    setAuthError(AUTH_ERROR_UNABLE_TO_EXCHANGE_CODE_FOR_TOKENS);
  }
}

/**
 * Refreshes the access token.
 *
 * If refresh token is null, clear auth and redirect to welcome page
 * If refresh token is not null but refresh did not work, clear auth and redirect to welcome page
 * If refresh token is not null and refresh did work, set updated token(s) and return
 */
export async function refreshAccessToken() {
  const refreshToken = getRefreshToken();
  if (refreshToken === null) {
    setAuthError(AUTH_ERROR_NO_REFRESH_TOKEN);
    setFlagAuthRedirectNeeded(REDIRECT_TO_SIGNOUT);
    return;
  }

  const domain = awsConfig.Auth.oauth.domain;
  const url = `https://${domain}/oauth2/token`;
  const details = {
    refresh_token: refreshToken!,
    grant_type: "refresh_token",
    client_id: awsConfig.Auth.userPoolWebClientId!, // tell typescript trust this is a string
    redirect_uri: awsConfig.Auth.oauth.redirectSignIn!, // tell typescript trust this is a string
  };

  const response = await fetch(url, {
    method: "POST",
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
    body: Object.entries(details)
      .map(
        ([key, value]) =>
          encodeURIComponent(key) + "=" + encodeURIComponent(value)
      )
      .join("&"),
  });

  if (!response.ok) {
    setAuthError(AUTH_ERROR_UNABLE_TO_REFRESH_TOKEN);
    setFlagAuthRedirectNeeded(REDIRECT_TO_SIGNOUT);
    return;
  }

  // Cache tokens
  try {
    const tokenJson: RefreshedTokens = RefreshedTokensSchema.parse(
      await response.json()
    );
    setAccessToken(tokenJson.access_token);
  } catch {
    console.error(
      "Unable to parse refreshed token json. Going back to login page."
    );
    setAuthError(AUTH_ERROR_UNEXPECTED_TOKEN_RESPONSE);
    setFlagAuthRedirectNeeded(REDIRECT_TO_SIGNOUT);
    return;
  }
  resetAuthenticationTimeoutTargets(false);
}

/**
 * Attempts to revoke the Cognito tokens
 *
 */
export async function revokeTokens() {
  const refreshToken = getRefreshToken();
  if (refreshToken === null) {
    debug("refresh token is null.  signing user out locally.");
    setAuthError(AUTH_ERROR_NO_REFRESH_TOKEN_TO_REVOKE);
  } else {
    const domain = awsConfig.Auth.oauth.domain;
    const url = `https://${domain}/oauth2/revoke`;
    const details = {
      token: getRefreshToken()!,
      client_id: awsConfig.Auth.userPoolWebClientId!, // tell typescript trust this is a string
    };
    debug("Revoking Cognito tokens");
    const response = await fetch(url, {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: Object.entries(details)
        .map(
          ([key, value]) =>
            encodeURIComponent(key) + "=" + encodeURIComponent(value)
        )
        .join("&"),
    });

    if (response.ok) {
      debug("Successful Cognito token revocation");
      if (getAuthMessage() === null && getAuthError() === null) {
        setAuthMessage(AUTH_MESSAGE_SIGNED_OUT);
      }
    } else {
      debug("Failed to revoke Cognito tokens");
      setAuthError(AUTH_ERROR_FAILED_TO_REVOKE_TOKENS);
    }
  }
  clearAuthTokens(true);
  clearRedirectLocation();
  launchCognitoLogoutUrl();
}

/**
 * Sets end dates/times for refreshing the access token and forcing a sign out
 */
function resetAuthenticationTimeoutTargets(reset_refresh_timeout: boolean) {
  const timeToRefresh =
    +new Date() +
    1000 /* milliseconds in second */ *
      60 /* seconds in minute */ *
      15; /* access token lasts 15 minutes */
  localStorage.setItem("auth_time_to_refresh_access_token", "" + timeToRefresh);

  if (reset_refresh_timeout) {
    const timeToLogout =
      +new Date() +
      1000 /* milliseconds in second */ *
        60 /* seconds in minute */ *
        60 /* minutes in hour */ *
        8; /* auth lasts 8 hours */
    localStorage.setItem("auth_time_to_logout", "" + timeToLogout);
  }
}

export function setFlagAuthRedirectNeeded(redirectLocation: string) {
  setRedirectLocation(redirectLocation);
  clearAuthTokens();
  clearAuthEndTimes();
  localStorage.setItem("auth_flag_redirect_needed", "true");
}

function clearFlagAuthRedirectNeeded() {
  localStorage.removeItem("auth_flag_redirect_needed");
}

function getFlagAuthRedirectNeeded(): string | null {
  return localStorage.getItem("auth_flag_redirect_needed");
}

function setRedirectLocation(key: string) {
  localStorage.setItem("auth_flag_redirect_location", key);
}

export function clearRedirectLocation() {
  localStorage.removeItem("auth_flag_redirect_location");
}

export function getRedirectLocation(): string | null {
  return localStorage.getItem("auth_flag_redirect_location");
}

export function startAuthenticationStatusChecks(
  setUpdateRequest: (value: SetStateAction<boolean>) => void
) {
  return setInterval(() => {
    if (statusCheckRedirectRequested() || statusCheckTokensExpired()) {
      setUpdateRequest(true);
    }
  }, 1000);
}

/**
 * Checks if a refresh / update was requested
 *
 * @returns true if the app should update at the `Router` level
 */
function statusCheckRedirectRequested(): boolean {
  if (getFlagAuthRedirectNeeded() !== null) {
    debug(
      "Flag auth_flag_redirect_needed is set. Logging user out and redirecting to: " +
        getRedirectLocation()
    );
    clearFlagAuthRedirectNeeded();
    return true;
  }
  debug("Flag auth_flag_redirect_needed is unset. not redirecting user.");
  return false;
}

/**
 * Checks if the auth tokens (access and refresh) have expired
 *
 * @returns true if the app should update at the `Router` level
 */
function statusCheckTokensExpired(): boolean {
  const authTimeToRefreshAccessTokenString = localStorage.getItem(
    "auth_time_to_refresh_access_token"
  );
  const authTimeToLogoutString = localStorage.getItem("auth_time_to_logout");

  if (
    authTimeToRefreshAccessTokenString === null ||
    authTimeToLogoutString === null
  ) {
    debug(
      "Auth check - user is not logged in yet or timeouts are being reset…"
    );
    return false;
  }

  try {
    const leftToGoForLogout =
      parseInt(authTimeToLogoutString) - new Date().getTime();
    const leftToGoForRefresh =
      parseInt(authTimeToRefreshAccessTokenString) - new Date().getTime();

    debug(
      "Auth check: refresh end time = " +
        leftToGoForRefresh +
        ", logout end time = " +
        leftToGoForLogout
    );

    if (leftToGoForLogout <= 0) {
      debug("Logout duration hit - signing out the user automatically.");
      clearAuthEndTimes();
      setAuthMessage(AUTH_MESSAGE_AUTO_SIGNED_OUT);
      setFlagAuthRedirectNeeded(REDIRECT_TO_SIGNOUT);
      return true;
    } else if (leftToGoForRefresh <= 0) {
      // Failure conditions are handled in the refresh method
      localStorage.removeItem("auth_time_to_refresh_access_token");
      refreshAccessToken();
    }
  } catch {
    debug(
      "Something went unexpectedly wrong with the localStorage values - signing out the user automatically."
    );
    setAuthMessage(AUTH_ERROR_UNABLE_TO_DETERMINE_AUTH_TIMEOUT_AUTO_SIGNED_OUT);
    setFlagAuthRedirectNeeded(REDIRECT_TO_SIGNOUT);
    return true;
  }
  return false;
}

export function launchCognitoLogin() {
  const domain = awsConfig.Auth.oauth.domain;
  const redirectSignIn = awsConfig.Auth.oauth.redirectSignIn;
  const responseType = awsConfig.Auth.oauth.responseType;
  const clientId = awsConfig.Auth.userPoolWebClientId;
  const cognitoIdpName = "okta";

  window.location.assign(
    `https://${domain}/oauth2/authorize?identity_provider=${cognitoIdpName}&redirect_uri=${redirectSignIn}&response_type=${responseType}&client_id=${clientId}`
  );
}

export function launchCognitoLogoutUrl() {
  const domain = awsConfig.Auth.oauth.domain;
  const redirectSignOut = awsConfig.Auth.oauth.redirectSignOut;
  const clientId = awsConfig.Auth.userPoolWebClientId;

  window.location.assign(
    `https://${domain}/logout?logout_uri=${redirectSignOut}&client_id=${clientId}`
  );
}
