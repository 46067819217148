import { z } from "zod";
import isSortedAscending from "./isSortedAscending";
import { isValid, parseISO } from "date-fns";

/**
 * A utility to tag a type as a subtype.
 *
 * @remarks
 * Tagged types, also known as Branded types or Opaque types,
 * are useful for noting that a type is not replaceable with another type of the same shape,
 * instead it needs to be parsed/validated first.
 *
 * For example a UUID is a specific type of string.
 * A name or description cannot replace a UUID.
 */
type Tagged<T, Tag> = T & { __tag: Tag };

export type Uuid = Tagged<string, "UUID">;
/**
 * A universally unique identifier.
 *
 * @remarks
 * often used as a primary key for database entities.
 */
export const UuidSchema = z.string().uuid() as unknown as z.Schema<Uuid>;

export const UuidListSchema = z.array(UuidSchema);
export type UuidList = z.infer<typeof UuidListSchema>;

export const ISODateSchema = z
  .string()
  .nonempty()
  .transform((date) => parseISO(date))
  .refine((maybeDate) => isValid(maybeDate), { message: "invalid date string" })
  .or(z.date());

/**
 * A level represents a grade range.
 *
 * @remarks
 * ES is Elementary School
 * MS is Middle School
 * HS is High School
 */
export const LevelSchema = z.union([
  z.literal("ES"),
  z.literal("MS"),
  z.literal("HS"),
]);
export const LevelAllSchema = LevelSchema.or(z.literal("All"));
export type LevelAll = z.infer<typeof LevelAllSchema>;
/**
 * A score represents how many questions a student got correct
 */
export const ScoreSchema = z.number().int().nonnegative();
/**
 * A percentage is a score, normalized to a 0-100% range
 */
export const PercentageSchema = ScoreSchema.max(100);

/**
 * A number or string representing a unique numeric identifier
 */
export const NumericIdSchema = z
  .number()
  .int()
  .nonnegative()
  .or(
    z
      .string()
      .regex(/^\d+$/)
      .transform((value) => parseInt(value, 10))
      .refine((value) => value >= 0)
  );

/**
 * A boolean or string representing a boolean
 */
export const BooleanSchema = z.boolean();

/**
 * Entity names have one or more, non-space characters in them
 */
export const NameSchema = z
  .string()
  .transform((name) => name.trim())
  .refine((name) => name.length > 0, { message: "Name must not be empty" });
export type Name = z.infer<typeof NameSchema>;

export const AssessmentStateSchema = z.union([
  z.literal("Unmapped"),
  z.literal("Mapped"),
  z.literal("No Scale Required"),
]);

export const AssessmentStateDropdownSchema = z.union([
  z.literal("All"),
  AssessmentStateSchema,
]);

/**
 * A scale list, is an overview representation of scales
 */
export const ScaleListSchema = z.object({
  total_num_of_results: z.number().int().nonnegative(),
  num_of_results: z.number().int().nonnegative(),
  scales: z.array(
    z.object({
      id: UuidSchema,
      name: NameSchema,
      level: LevelSchema,
    })
  ),
});
export type ScaleList = z.infer<typeof ScaleListSchema>;

/**
 * A parsed representation of a comma separated value file.
 */
export const CsvSchema = z
  .array(
    z
      .array(z.string())
      .min(3, {
        message:
          "Spreadsheet needs to have all three required columns populated with values",
      })
      .max(3, {
        message:
          "Additional columns and notes cannot not stored, please move notes to another sheet",
      })
  )
  .min(4, {
    message: "Spreadsheet needs to have at least one row of values populated",
  });
export type Csv = z.infer<typeof CsvSchema>;

/**
 * Maps a raw score to its normalized percentage value, and scaled percentage value
 */
export const ScaledPointSchema = z.object({
  raw_score: ScoreSchema,
  raw_percent: PercentageSchema,
  scaled_percent: PercentageSchema,
});
export type ScaledPoint = z.infer<typeof ScaledPointSchema>;

/**
 * Detailed representation of a scale, including mappings of scores to percentages.
 */
export const ScaleSchema = z.object({
  id: UuidSchema.optional(),
  name: NameSchema,
  level: LevelSchema,
  rows: z
    .array(ScaledPointSchema)
    .nonempty()
    .refine(
      (scores) => {
        const rawScores = scores.map(({ raw_score }) => raw_score);
        return isSortedAscending(rawScores.reverse());
      },
      {
        message: "raw scores should be in descending order",
        path: ["raw_score"],
      }
    )
    .refine(
      (scores) => {
        const rawPercent = scores.map(({ raw_percent }) => raw_percent);
        return isSortedAscending(rawPercent.reverse());
      },
      {
        message: "percentage scores should be in descending order",
        path: ["percentage_score"],
      }
    )
    .refine(
      (scores) => {
        const scaledPercent = scores.map(
          ({ scaled_percent }) => scaled_percent
        );
        return isSortedAscending(scaledPercent.reverse());
      },
      {
        message: "scaled scores should be in descending order",
        path: ["scaled_score"],
      }
    )
    .refine(
      (scores) => {
        const rawScores = scores.map(({ raw_score }) => raw_score);
        return rawScores.length === new Set(rawScores).size;
      },
      {
        message: "raw scores should be unique",
        path: ["raw_score"],
      }
    )
    .refine(
      (scores) => {
        const percentScore = scores.map(({ raw_percent }) => raw_percent);
        return (
          percentScore.length ===
          // under 100 points, expect all percents to be unique
          new Set(percentScore).size +
            // for over 100 point, expect duplicates
            Math.max(percentScore.length - 101, 0)
        );
      },
      {
        message: "percentage scores should be unique",
        path: ["percentage_score"],
      }
    )
    .refine(
      (scores) => {
        return scores.map(({ raw_score }) => raw_score).includes(0);
      },
      { message: "raw scores must start at 0" }
    )
    .refine(
      (scores) => {
        return scores.map(({ raw_percent }) => raw_percent).includes(0);
      },
      { message: "raw percentages must start at 0%" }
    )
    .refine(
      (scores) => {
        return scores.map(({ raw_percent }) => raw_percent).includes(100);
      },
      { message: "raw percentages must go to 100%" }
    ),
});
export type Scale = z.infer<typeof ScaleSchema>;

/**
 * Range of values to map to label
 *
 * @remarks
 * this specifically checks scores, which have no upper bound
 */
const LabelScoreSchema = z
  .object({
    min: ScoreSchema,
    max: ScoreSchema,
    label: z.string().nonempty(),
  })
  .refine(({ min, max }) => min <= max, {
    message: "min must be less than or equal to max",
    path: ["min"],
  });

export type LabelScore = z.infer<typeof LabelScoreSchema>;

/**
 * Types of label which can be mapped
 */
export const LabelTypeSchema = z.union([
  z.literal("PERFORMANCE"),
  z.literal("CUSP_PERFORMANCE"),
  z.literal("MASTERY"),
]);
export type LabelType = z.infer<typeof LabelTypeSchema>;

/**
 * Which version of scores from the assessment and scale should be used for mapping
 */
export const LabelSourceSchema = z.union([
  z.literal("RAW_SCORE"),
  z.literal("RAW_PERCENT"),
  z.literal("SCALED_PERCENT"),
]);
export type LabelSource = z.infer<typeof LabelSourceSchema>;

/**
 * Represents a mapping of ranges of scores/percentages to labels for performance.
 *
 * @remarks
 * this is a union type to support validations specific to the data source being used.
 */
export const LabelSetSchema = z
  .object({
    id: UuidSchema.optional(),
    name: NameSchema,
    level: LevelSchema,
    type: LabelTypeSchema,
    source: LabelSourceSchema,
    labels: z.array(LabelScoreSchema).min(2),
  })
  .refine(
    ({ labels }) =>
      labels.every(
        (_, index, labels) =>
          index < 1 || labels[index].max < labels[index - 1].min
      ),
    {
      message: "performance labels cannot have overlapping min and max",
      path: ["labels"],
    }
  )
  .refine(
    ({ labels }) =>
      labels.every(
        (_, index, labels) =>
          index < 1 || labels[index - 1].min - 1 === labels[index].max
      ),
    {
      message:
        "performance label min must be one point greater than the next label max",
      path: ["labels"],
    }
  );
export type LabelSet = z.infer<typeof LabelSetSchema>;

/**
 * label sets for performance, an overview list
 */
export const LabelSetListSchema = z.object({
  total_num_of_results: z.number().int().nonnegative(),
  num_of_results: z.number().int().nonnegative(),
  performance_label_sets: z.array(
    z.object({
      id: UuidSchema,
      name: NameSchema,
      level: LevelSchema,
      type: LabelTypeSchema,
      source: LabelSourceSchema,
    })
  ),
});
export type LabelSetList = z.infer<typeof LabelSetListSchema>;

/**
 * Overview representation of assessments
 */
export const AssessmentListSchema = z.object({
  num_of_results: z.number().int().nonnegative(),
  total_num_of_results: z.number().int().nonnegative(),
  assessments: z.array(
    z.object({
      id: NumericIdSchema,
      name: NameSchema,
      type: z.literal("BY_ID"),
      origin: z.union([z.literal("IOA"), z.literal("SS")]),
      state: AssessmentStateSchema,
      is_associated: z.boolean(),
      scale_required: z.boolean(),
      created_by: z.string(),
      date_created: ISODateSchema,
      last_updated_by: z.string(),
      date_last_updated: ISODateSchema,
    })
  ),
});
export type AssessmentList = z.infer<typeof AssessmentListSchema>;

/**
 * Association between an Assessment, its scale, and optionally label sets
 */
export const AssociationSchema = z.object({
  assessment_id: NumericIdSchema,
  scale_id: UuidSchema,
  performance_label_set_ids: z.array(UuidSchema),
});
export type Association = z.infer<typeof AssociationSchema>;

/**
 * variation of AssociationSchema, with context needed to update/upsert an association.
 * @note
 * some properties have newer names which are not yet matched by original AssociationSchema
 * potentially work with backend to unify the naming
 */
export const AssociationPutSchema = z.object({
  association_id: UuidSchema,
  assessment_id: NumericIdSchema,
  scale_id: UuidSchema,
  label_set_ids: z.array(UuidSchema),
});
export type AssociationPut = z.infer<typeof AssociationPutSchema>;

/**
 * Create a refinement for a list of objects.
 * Checks that a given column key is present in all or none of the rows of the list.
 *
 * @param column object key
 * @returns refinement
 */
function allOrNothingRefinement<T>(
  column: keyof T
): [
  (results: Partial<T>[]) => boolean,
  { message: string; path: (keyof T)[] }
] {
  return [
    (results: Partial<T>[]): boolean =>
      results.every((row) => typeof row[column] === "undefined") ||
      results.every((row) => typeof row[column] === "string"),
    {
      message: `${column} must be provided for all rows`,
      path: [column],
    },
  ];
}

export const AssociationMappingListSchema = z.array(
  z.object({
    association: z.object({ id: UuidSchema }),
    assessment: z.object({
      id: NumericIdSchema,
      name: NameSchema,
      created_by: z.string(),
      date_created: ISODateSchema,
      last_updated_by: z.string(),
      date_last_updated: ISODateSchema,
    }),
    scale: z.object({
      id: UuidSchema,
      name: NameSchema,
      level: LevelSchema,
      created_by: z.string(),
      date_created: ISODateSchema,
      last_updated_by: z.string(),
      date_last_updated: ISODateSchema,
    }),
    label_sets: z.array(
      z.object({
        id: UuidSchema,
        name: NameSchema,
        level: LevelSchema,
        created_by: z.string(),
        date_created: ISODateSchema,
        last_updated_by: z.string(),
        date_last_updated: ISODateSchema,
      })
    ),
    mappings: z
      .object({
        alerts: z.array(
          z.object({
            association_id: UuidSchema,
            test_id: NumericIdSchema,
            labels_set_id: UuidSchema,
            reason: z.string(),
          })
        ),
        results: z
          .array(
            z.object({
              test_id: NumericIdSchema,
              raw_score: ScoreSchema,
              raw_percent: PercentageSchema,
              scaled_percent: PercentageSchema,
              raw_score_performance_level: z.string().optional(),
              raw_percent_performance_level: z.string().optional(),
              raw_score_cusp_performance_level: z.string().optional(),
              raw_percent_cusp_performance_level: z.string().optional(),
              raw_score_mastery_level: z.string().optional(),
              raw_percent_mastery_level: z.string().optional(),
              scaled_percent_mastery_level: z.string().optional(),
              scaled_percent_cusp_performance_level: z.string().optional(),
              scaled_percent_performance_level: z.string().optional(),
            })
          )
          .refine(...allOrNothingRefinement("raw_score_performance_level"))
          .refine(...allOrNothingRefinement("raw_percent_performance_level"))
          .refine(...allOrNothingRefinement("raw_score_cusp_performance_level"))
          .refine(
            ...allOrNothingRefinement("raw_percent_cusp_performance_level")
          )
          .refine(...allOrNothingRefinement("raw_score_mastery_level"))
          .refine(...allOrNothingRefinement("raw_percent_mastery_level"))
          .refine(...allOrNothingRefinement("scaled_percent_mastery_level"))
          .refine(
            ...allOrNothingRefinement("scaled_percent_cusp_performance_level")
          )
          .refine(
            ...allOrNothingRefinement("scaled_percent_performance_level")
          ),
      })
      .refine(
        ({ alerts, results }) =>
          (alerts.length <= 0 && results.length > 0) ||
          (alerts.length > 0 && results.length <= 0),
        {
          message: "Mapping can only be valid or invalid, not both",
          path: ["results"],
        }
      )
      .optional(),
  })
);
export type AssociationMappingList = z.infer<
  typeof AssociationMappingListSchema
>;

export const AssociationListSchema = z.array(
  z.object({
    association: z.object({ id: UuidSchema }),
    assessment: z.object({ id: NumericIdSchema }),
    scale: z.object({ id: UuidSchema }),
    label_sets: z.array(
      z.object({
        id: UuidSchema,
      })
    ),
  })
);
export type AssociationList = z.infer<typeof AssociationListSchema>;

/**
 * Detailed representation of an auth token response from Cognito.
 */
export const TokensSchema = z.object({
  id_token: z.string().nonempty(),
  access_token: z.string().nonempty(),
  refresh_token: z.string().nonempty(),
});
export type Tokens = z.infer<typeof TokensSchema>;

/**
 * Detailed representation of a refresh auth token response from Cognito.
 */
export const RefreshedTokensSchema = z.object({
  id_token: z.string().nonempty(),
  access_token: z.string().nonempty(),
});
export type RefreshedTokens = z.infer<typeof RefreshedTokensSchema>;
