import { StrictMode } from "react";
import * as ReactDOM from "react-dom";
import "./index.scss";
import Router from "./Router";
import reportWebVitals from "./reportWebVitals";
import { inspect } from "@xstate/inspect";

if (process.env.NODE_ENV === "development") {
  inspect({ url: "https://statecharts.io/inspect", iframe: false });
}

ReactDOM.render(
  <StrictMode>
    <Router />
  </StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
