import type { ReactNode } from "react";
import classname from "clsx";

import "./Message.scss";

export interface MessageProps {
  variant?: "success" | "failure";
  children: ReactNode;
}

export default function Message({ variant, children }: MessageProps) {
  return (
    <section
      className={classname("message", {
        "message--default": variant === undefined,
        "message--success": variant === "success",
        "message--failure": variant === "failure",
      })}
    >
      {children}
    </section>
  );
}
